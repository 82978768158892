<template>
  <div>
    <b-card>
      <div class="d-flex align-items-center justify-content-between">
        <b-form-input
            id="search"
            v-model="ean"
            :placeholder="$i18n.t('fotostudio.search.placeholder')"
            class="w-75"
            type="search"
        />
        <b-button
            class="mt-2 mb-2 mr-0"
            type="submit"
            variant="primary"
            @click="search()"
        >
          {{ $i18n.t('fotostudio.search.button') }}
        </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import DataService from "@/views/fotostudio/services/DataService";

export default {
  data() {
    return {
      service: new DataService(),
      ean: "",
    }
  },
  methods: {
    search() {
      if (this.ean === "") return;
      this.service.search(this.ean).then(response => {
        const res = response.data.data
        if (res === 1) {
          this.$router.replace(`/fotostudio/search/${this.ean}`)
        } else if (res === 2) {
          this.alreadyConfirmed()
        } else if (res === 0) {
          this.notFoundResult()
        }
      }).catch(error => {
        this.notFoundResult()
      })
    },
    alreadyConfirmed() {
      this.$swal({
        title: this.$i18n.t("fotostudio.search.already_confirmed.title"),
        text: this.$i18n.t("fotostudio.search.already_confirmed.message"),
        icon: "error",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: this.$i18n.t('fotostudio.search.already_confirmed.close'),
        customClass: {
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    notFoundResult() {
      this.$swal({
        title: this.$i18n.t("fotostudio.search.not_found.title"),
        text: this.$i18n.t("fotostudio.search.not_found.message"),
        icon: "error",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: this.$i18n.t('fotostudio.search.not_found.close'),
        customClass: {
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  }
}
</script>
